.SelectColor {
    position: relative;
    display: flex;
    align-items: flex-end;
}

.SelectColor__ColorButton {
    width: 40px;
    height: 20px;
    padding: 4px;
    border-radius: 4px;
    border: 2px solid white;
    display: inline-block;
    margin-left: 8px;
    margin-top: 4px;
    text-align: center;
    line-height: 0.5;
    cursor: pointer;
}

.SelectColor__RandomCheckbox {
    display: inline-block;
    margin-left: 8px;
}

.SelectColor__ColorsView {
    position: absolute;
    z-index: 9999;
}

.SelectColor__BottomPosition {
    right: 0;
    /* top: 0; */
}