.Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    background-color: #485c6b;/* old #485c6b new #243A49*/
}

.Container_Content {
    margin-top: 0px;
}