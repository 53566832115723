.Donations {
  display: flex;
  justify-content: center;
}

.DonateBlock {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Donations__LetterBody {
  font-family: medium-content-serif-font, Georgia, Cambria, "Times New Roman",
    Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 1.58;
  letter-spacing: -0.003em;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.68);
  border-radius: 4px;
  box-shadow: 0 0 20px #0000006e;
  padding: 20px;
  color: black;
  margin-bottom: 50px;
}

.pt-dark .Donations__LetterBody h1 {
  color: black;
}
