@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Lora);
@import url(https://fonts.googleapis.com/css?family=Pacifico);
@import url(https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz);
@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Amatic+SC);
@import url(https://fonts.googleapis.com/css?family=Marmelad);
@import url(https://fonts.googleapis.com/css?family=Pattaya);
@import url(https://fonts.googleapis.com/css?family=Rubik+Mono+One);
@import url(https://fonts.googleapis.com/css?family=Acme);
@import url(https://fonts.googleapis.com/css?family=Baloo+Thambi);
@import url(https://fonts.googleapis.com/css?family=Bonbon);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif);
@import url(https://fonts.googleapis.com/css?family=Cantarell:700i);
@import url(https://fonts.googleapis.com/css?family=Caveat);
@import url(https://fonts.googleapis.com/css?family=Charmonman);
@import url(https://fonts.googleapis.com/css?family=Clicker+Script);
@import url(https://fonts.googleapis.com/css?family=Courgette);
@import url(https://fonts.googleapis.com/css?family=Crafty+Girls);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script);
@import url(https://fonts.googleapis.com/css?family=Dhurjati);
@import url(https://fonts.googleapis.com/css?family=Finger+Paint);
@import url(https://fonts.googleapis.com/css?family=Fredericka+the+Great);
@import url(https://fonts.googleapis.com/css?family=Gaegu);
@import url(https://fonts.googleapis.com/css?family=Great+Vibes);
@import url(https://fonts.googleapis.com/css?family=Gugi);
@import url(https://fonts.googleapis.com/css?family=Hi+Melody);
@import url(https://fonts.googleapis.com/css?family=Lancelot);
@import url(https://fonts.googleapis.com/css?family=League+Script);
@import url(https://fonts.googleapis.com/css?family=Life+Savers);
@import url(https://fonts.googleapis.com/css?family=Lily+Script+One);
@import url(https://fonts.googleapis.com/css?family=Love+Ya+Like+A+Sister);
@import url(https://fonts.googleapis.com/css?family=Milonga);
@import url(https://fonts.googleapis.com/css?family=Mogra);
@import url(https://fonts.googleapis.com/css?family=Monoton);
@import url(https://fonts.googleapis.com/css?family=Mr+Bedfort);
@import url(https://fonts.googleapis.com/css?family=Niconne);
@import url(https://fonts.googleapis.com/css?family=Parisienne);
@import url(https://fonts.googleapis.com/css?family=Ranga);
@import url(https://fonts.googleapis.com/css?family=Ruthie);
@import url(https://fonts.googleapis.com/css?family=Sacramento);
@import url(https://fonts.googleapis.com/css?family=Satisfy);
@import url(https://fonts.googleapis.com/css?family=Schoolbell);
@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light);
@import url(https://fonts.googleapis.com/css?family=Sofadi+One);
@import url(https://fonts.googleapis.com/css?family=Sofia);
@import url(https://fonts.googleapis.com/css?family=The+Girl+Next+Door);
@import url(https://fonts.googleapis.com/css?family=Ultra);
@import url(https://fonts.googleapis.com/css?family=VT323);
@import url(https://fonts.googleapis.com/css?family=Yeon+Sung);
@import url(https://fonts.googleapis.com/css?family=ZCOOL+KuaiLe);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.SelectColor {
    position: relative;
    display: flex;
    align-items: flex-end;
}

.SelectColor__ColorButton {
    width: 40px;
    height: 20px;
    padding: 4px;
    border-radius: 4px;
    border: 2px solid white;
    display: inline-block;
    margin-left: 8px;
    margin-top: 4px;
    text-align: center;
    line-height: 0.5;
    cursor: pointer;
}

.SelectColor__RandomCheckbox {
    display: inline-block;
    margin-left: 8px;
}

.SelectColor__ColorsView {
    position: absolute;
    z-index: 9999;
}

.SelectColor__BottomPosition {
    right: 0;
    /* top: 0; */
}
.WidgetURL_Warning {
    font-size: 14px;
    font-weight: 400;
}
.WidgetEditLine {
    margin-top: 20px;
    margin-bottom: 20px;
}

.range-slider {
  
}

.range-slider > div {
  display: flex;
}

.range-slider > div > span {
  margin-right: 20px;
}
.RenderTips {
  position: absolute;
  width: 100%;
  height: 100%;
}

.RenderTips_NY2019 {
  width: 400px;
  height: 400px;
  position: absolute;
  top: -136px;
  left: -140px;
}

.RenderTips_Tip {
  width: 150px;
  height: 150px;
  -webkit-animation: 3s linear;
          animation: 3s linear;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  font-size: 24px;
}

.RenderTips_Tip--animation_bottom-top {
  -webkit-animation-name: Tip-bottom-top;
          animation-name: Tip-bottom-top;
}

.RenderTips_Tip--animation_top-bottom {
  -webkit-animation-name: Tip-top-bottom;
          animation-name: Tip-top-bottom;
}

.RenderTips_Tip--animation_left-right {
  -webkit-animation-name: Tip-left-right;
          animation-name: Tip-left-right;
}

.RenderTips_Tip--animation_right-left {
  -webkit-animation-name: Tip-right-left;
          animation-name: Tip-right-left;
  left: auto;
  right: 0;
}

.RenderTips_Tip--animation_fade-in-out {
  -webkit-animation-name: Tip-fade-in-out;
          animation-name: Tip-fade-in-out;
  top: 40%;
  left: 40%;
}

.RenderTips_Tip--animation_full-screen {
  -webkit-animation-name: Tip-fade-in-out;
          animation-name: Tip-fade-in-out;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.RenderTips_Tip--animation_none {
  -webkit-animation-name: None_animation;
          animation-name: None_animation;
  width: content;
  height: content;
  top: 30%;
  left: 40%;
}

.RenderTips_Tip--pause {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.RenderTips_Message {
  white-space: nowrap;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.RenderTips_Tip,
.RenderTips_TipImage,
.RenderTips_Tipper,
.RenderTips_Message,
.RenderTips_Amount {
  position: absolute;
}

.RenderTips_Background {
  padding: 0.108em 0.216em;
  border-radius: 0.216em;
}

.RenderTips_TipImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;
}


.RenderTips_Message.alignBottom,
.RenderTips_Amount.alignBottom,
.RenderTips_Tipper.alignBottom {
  bottom: 0;
  transform: translateY(50%);
}

.RenderTips_Message.alignTop,
.RenderTips_Amount.alignTop,
.RenderTips_Tipper.alignTop {
  top: 0;
  bottom: auto;
  transform: translateY(-50%);
}

.RenderTips_Message.alignMiddle,
.RenderTips_Amount.alignMiddle,
.RenderTips_Tipper.alignMiddle {
  top: auto;
  bottom: auto;
}

.RenderTips_Message.alignLeft,
.RenderTips_Amount.alignLeft,
.RenderTips_Tipper.alignLeft {
  left: 0;
  transform: translateX(-50%);
}

.RenderTips_Message.alignRight,
.RenderTips_Amount.alignRight,
.RenderTips_Tipper.alignRight {
  right: 0;
  left: auto;
  transform: translateX(50%);
}

.RenderTips_Message.alignCenter,
.RenderTips_Amount.alignCenter,
.RenderTips_Tipper.alignCenter {
  left: auto;
  right: auto;
}


.RenderTips_Message.alignTop.alignRight,
.RenderTips_Amount.alignTop.alignRight,
.RenderTips_Tipper.alignTop.alignRight {
  transform: translate(50%, -50%);
}

.RenderTips_Message.alignBottom.alignRight,
.RenderTips_Amount.alignBottom.alignRight,
.RenderTips_Tipper.alignBottom.alignRight {
  transform: translate(50%, 50%);
}

.RenderTips_Message.alignBottom.alignLeft,
.RenderTips_Amount.alignBottom.alignLeft,
.RenderTips_Tipper.alignBottom.alignLeft {
  transform: translate(-50%, 50%);
}

.RenderTips_Message.alignTop.alignLeft,
.RenderTips_Amount.alignTop.alignLeft,
.RenderTips_Tipper.alignTop.alignLeft {
  transform: translate(-50%, -50%);
}
/*
.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(1),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(1) {
  animation-delay: 0.1s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(2),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(2) {
  animation-delay: 0.15s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(3),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(3) {
  animation-delay: 0.2s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(4),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(4) {
  animation-delay: 0.12s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(5),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(5) {
  animation-delay: 0.08s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(6),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(6) {
  animation-delay: 0.25s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(7),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(7) {
  animation-delay: 0.13s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(8),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(8) {
  animation-delay: 0.3s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(9),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(9) {
  animation-delay: 0.1s;
}
*/

@-webkit-keyframes Tip-bottom-top {
  0% {
    opacity: 0;
    bottom: 0;
    left: 10%;
  }

  30% {
    opacity: 1;
  }

  40% {
    left: 30%;
    bottom: 50%;
  }

  60% {
    opacity: 1;
    bottom: 55%;
    left: 30%;
  }

  100% {
    opacity: 0;
    bottom: 100%;
    left: 0;
  }
}

@keyframes Tip-bottom-top {
  0% {
    opacity: 0;
    bottom: 0;
    left: 10%;
  }

  30% {
    opacity: 1;
  }

  40% {
    left: 30%;
    bottom: 50%;
  }

  60% {
    opacity: 1;
    bottom: 55%;
    left: 30%;
  }

  100% {
    opacity: 0;
    bottom: 100%;
    left: 0;
  }
}

@-webkit-keyframes Tip-top-bottom {
  0% {
    opacity: 0;
    top: 0;
    left: 10%;
  }

  30% {
    opacity: 1;
  }

  40% {
    left: 30%;
    top: 50%;
  }

  60% {
    opacity: 1;
    top: 55%;
    left: 30%;
  }

  100% {
    opacity: 0;
    top: 100%;
    left: 0;
  }
}

@keyframes Tip-top-bottom {
  0% {
    opacity: 0;
    top: 0;
    left: 10%;
  }

  30% {
    opacity: 1;
  }

  40% {
    left: 30%;
    top: 50%;
  }

  60% {
    opacity: 1;
    top: 55%;
    left: 30%;
  }

  100% {
    opacity: 0;
    top: 100%;
    left: 0;
  }
}

@-webkit-keyframes Tip-left-right {
  0% {
    opacity: 0;
    left: 0;
    top: 50%;
  }

  30% {
    opacity: 1;
  }

  40% {
    top: 30%;
    left: 50%;
  }

  60% {
    opacity: 1;
    left: 55%;
    top: 30%;
  }

  100% {
    opacity: 0;
    left: 100%;
    top: 50%;
  }
}

@keyframes Tip-left-right {
  0% {
    opacity: 0;
    left: 0;
    top: 50%;
  }

  30% {
    opacity: 1;
  }

  40% {
    top: 30%;
    left: 50%;
  }

  60% {
    opacity: 1;
    left: 55%;
    top: 30%;
  }

  100% {
    opacity: 0;
    left: 100%;
    top: 50%;
  }
}

@-webkit-keyframes Tip-right-left {
  0% {
    opacity: 0;
    right: 0;
    top: 50%;
  }

  30% {
    opacity: 1;
  }

  40% {
    top: 30%;
    right: 50%;
  }

  60% {
    opacity: 1;
    right: 55%;
    top: 30%;
  }

  100% {
    opacity: 0;
    right: 100%;
    top: 50%;
  }
}

@keyframes Tip-right-left {
  0% {
    opacity: 0;
    right: 0;
    top: 50%;
  }

  30% {
    opacity: 1;
  }

  40% {
    top: 30%;
    right: 50%;
  }

  60% {
    opacity: 1;
    right: 55%;
    top: 30%;
  }

  100% {
    opacity: 0;
    right: 100%;
    top: 50%;
  }
}

@-webkit-keyframes Tip-fade-in-out {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes Tip-fade-in-out {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes None_animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes None_animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
.RenderTips_Tip--animation_bounceInDown,
.RenderTips_Tip--animation_bounceInUp,
.RenderTips_Tip--animation_bounceIn,
.RenderTips_Tip--animation_bounceInRight,
.RenderTips_Tip--animation_bounceInLeft,
.RenderTips_Tip--animation_zoomIn,
.RenderTips_Tip--animation_jackInTheBox,
.RenderTips_Tip--animation_zoomInDown,
.RenderTips_Tip--animation_zoomInUp,
.RenderTips_Tip--animation_fadeInUp {
  left: 30%;
  top: 30%;
}

.RenderTips_Tip--animation_fadeInUp {
    -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
  }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0%, 100%, 0);
  }
  45%,
  55% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0%, -200%, 0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0%, 100%, 0);
  }
  45%,
  55% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0%, -200%, 0);
  }
}

.RenderTips_Tip--animation_bounceInLeft {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInLeft {
  0%,
  60%,
  75%,
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  55% {
    transform: translate3d(-10px, 0, 0);
  }
  70%,
  80% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInLeft {
  0%,
  60%,
  75%,
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  55% {
    transform: translate3d(-10px, 0, 0);
  }
  70%,
  80% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_Tip--animation_bounceInRight {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
}

@-webkit-keyframes bounceInRight {
  0%,
  60%,
  75%,
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  55% {
    transform: translate3d(10px, 0, 0);
  }
  70%,
  80%  {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInRight {
  0%,
  60%,
  75%,
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  55% {
    transform: translate3d(10px, 0, 0);
  }
  70%,
  80%  {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_Tip--animation_bounceIn {
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.RenderTips_Tip--animation_bounceInDown {
  -webkit-animation-name: bounceInDown;
          animation-name: bounceInDown;
}

@-webkit-keyframes bounceInDown {
  0%,
  60%,
  75%,
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  55% {
    transform: translate3d(0, -10px, 0);
  }
  70%,
  80% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInDown {
  0%,
  60%,
  75%,
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  55% {
    transform: translate3d(0, -10px, 0);
  }
  70%,
  80% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_Tip--animation_bounceInUp {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
}

@-webkit-keyframes bounceInUp {
  0%,
  60%,
  75%,
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  55% {
    transform: translate3d(0, 10px, 0);
  }
  70%,
  80% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInUp {
  0%,
  60%,
  75%,
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  55% {
    transform: translate3d(0, 10px, 0);
  }
  70%,
  80% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_Tip--animation_zoomIn{
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
    -webkit-animation-timing-function: cubic-bezier(0.45, 0.31, 0, 1.1);
            animation-timing-function: cubic-bezier(0.45, 0.31, 0, 1.1);
  }
  30%{
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
    -webkit-animation-timing-function: cubic-bezier(0.45, 0.31, 0, 1.1);
            animation-timing-function: cubic-bezier(0.45, 0.31, 0, 1.1);
  }
  30%{
    opacity: 1;
  }
}

.RenderTips_Tip--animation_jackInTheBox {
  -webkit-animation-name: jackInTheBox;
          animation-name: jackInTheBox;
}

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }
  50% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }
  50% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.RenderTips_Tip--animation_zoomInDown {
  -webkit-animation-name: zoomInDown;
          animation-name: zoomInDown;
}

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  30% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
            animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
  50% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    -webkit-animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
            animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  30% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
            animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
  50% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    -webkit-animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
            animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
}

.RenderTips_Tip--animation_zoomInUp {
  -webkit-animation-name: zoomInUp;
          animation-name: zoomInUp;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  30% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
            animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
  50% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    -webkit-animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
            animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  30% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
            animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
  50% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    -webkit-animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
            animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
}

.RenderTips_TipImage--animated_classic {
  -webkit-animation: 1s TipImage infinite linear;
          animation: 1s TipImage infinite linear;
}

.RenderTips_Message--animated_classic span,
.RenderTips_Amount--animated_classic,
.RenderTips_Tipper--animated_classic {
  display: inline-block;
  letter-spacing: 2px;
}

.RenderTips_Amount--animated_classic {
  transform: scale(2);
  letter-spacing: 4px;
}

.RenderTips_Message--animated_classic,
.RenderTips_Amount--animated_classic,
.RenderTips_Tipper--animated_classic {
  -webkit-animation: 1s TipperNameLetters infinite linear;
          animation: 1s TipperNameLetters infinite linear;
}

@-webkit-keyframes TipperNameLetters {
  0% {
    transform: scale(1);
  }

  25% {
    transform: rotate(20deg);
  }

  50% {
    transform: scale(1.4) rotate(0);
  }

  75% {
    transform: rotate(-20deg);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes TipperNameLetters {
  0% {
    transform: scale(1);
  }

  25% {
    transform: rotate(20deg);
  }

  50% {
    transform: scale(1.4) rotate(0);
  }

  75% {
    transform: rotate(-20deg);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes TipImage {
  0% {
    transform: translateX(0) scale(1);
  }

  25% {
    transform: translateX(-10%) scale(1.2) rotate(10deg);
  }

  50% {
    transform: translateX(0) scale(0.8);
  }

  75% {
    transform: translateX(10%) scale(1) rotate(-10deg);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes TipImage {
  0% {
    transform: translateX(0) scale(1);
  }

  25% {
    transform: translateX(-10%) scale(1.2) rotate(10deg);
  }

  50% {
    transform: translateX(0) scale(0.8);
  }

  75% {
    transform: translateX(10%) scale(1) rotate(-10deg);
  }

  100% {
    transform: translateX(0);
  }
}

.RenderTips_TipImage--animated_bounce,
.RenderTips_Message--animated_bounce,
.RenderTips_Amount--animated_bounce,
.RenderTips_Tipper--animated_bounce {
  -webkit-animation: 2s bounce infinite linear;
          animation: 2s bounce infinite linear;
}

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.RenderTips_TipImage--animated_flash,
.RenderTips_Message--animated_flash,
.RenderTips_Amount--animated_flash,
.RenderTips_Tipper--animated_flash {
  -webkit-animation: 4s flash infinite linear;
          animation: 4s flash infinite linear;
}

@-webkit-keyframes flash {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes flash {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.RenderTips_TipImage--animated_pulse,
.RenderTips_Message--animated_pulse,
.RenderTips_Amount--animated_pulse,
.RenderTips_Tipper--animated_pulse {
  -webkit-animation: 2s pulse infinite ease-in-out;
          animation: 2s pulse infinite ease-in-out;
}

@-webkit-keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.25, 1.25, 1.25);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.25, 1.25, 1.25);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.RenderTips_TipImage--animated_rubberBand,
.RenderTips_Message--animated_rubberBand,
.RenderTips_Amount--animated_rubberBand,
.RenderTips_Tipper--animated_rubberBand {
  -webkit-animation: 2s rubberBand infinite ease-in-out;
          animation: 2s rubberBand infinite ease-in-out;
}

@-webkit-keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.RenderTips_TipImage--animated_shake,
.RenderTips_Message--animated_shake,
.RenderTips_Amount--animated_shake,
.RenderTips_Tipper--animated_shake {
  -webkit-animation: 2s shake infinite ease-in-out;
          animation: 2s shake infinite ease-in-out;
}

@-webkit-keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.RenderTips_TipImage--animated_swing,
.RenderTips_Message--animated_swing,
.RenderTips_Amount--animated_swing,
.RenderTips_Tipper--animated_swing {
  -webkit-animation: 2s swing infinite ease-in-out;
          animation: 2s swing infinite ease-in-out;
}

@-webkit-keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.RenderTips_TipImage--animated_tada,
.RenderTips_Message--animated_tada,
.RenderTips_Amount--animated_tada,
.RenderTips_Tipper--animated_tada {
  -webkit-animation: 2s tada infinite ease-in-out;
          animation: 2s tada infinite ease-in-out;
}

@-webkit-keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.RenderTips_TipImage--animated_wobble,
.RenderTips_Message--animated_wobble,
.RenderTips_Amount--animated_wobble,
.RenderTips_Tipper--animated_wobble {
  -webkit-animation: 2s wobble infinite ease-in-out;
          animation: 2s wobble infinite ease-in-out;
}

@-webkit-keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_TipImage--animated_jello,
.RenderTips_Message--animated_jello,
.RenderTips_Amount--animated_jello,
.RenderTips_Tipper--animated_jello {
  -webkit-animation: 2s jello infinite ease-in-out;
          animation: 2s jello infinite ease-in-out;
}

@-webkit-keyframes jello {
  from,
  11.1%,
  to {
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes jello {
  from,
  11.1%,
  to {
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.RenderTips_TipImage--animated_heartBeat,
.RenderTips_Message--animated_heartBeat,
.RenderTips_Amount--animated_heartBeat,
.RenderTips_Tipper--animated_heartBeat {
  -webkit-animation: 1.3s heartBeat infinite ease-in-out;
          animation: 1.3s heartBeat infinite ease-in-out;
}

@-webkit-keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
}

.Home {
  margin: 20px auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Home tbody td {
  text-align: center;
}

.Home tbody td:first-child {
  text-align: left;
}

.Home .RenderTips_Tip {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  top: 0;
  left: 0;
}
.Auth {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    align-items: center;
}

.Auth__Item {
    margin-top: 20px;
}

.Auth__Options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 26px;
}

.SignIn {
    margin: 20px 0;
}
.SignUp {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ResetPassword {
    margin: 20px;
}
.Render {
    width: 100%;
    height: 100%;
}

.Stats__Table td {
    padding: 10px 10px 10px 4px;
    border: 1px solid white;
}
.Donations {
  display: flex;
  justify-content: center;
}

.DonateBlock {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Donations__LetterBody {
  font-family: medium-content-serif-font, Georgia, Cambria, "Times New Roman",
    Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 1.58;
  letter-spacing: -0.003em;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.68);
  border-radius: 4px;
  box-shadow: 0 0 20px #0000006e;
  padding: 20px;
  color: black;
  margin-bottom: 50px;
}

.pt-dark .Donations__LetterBody h1 {
  color: black;
}

.Contacts {
  margin: 0 auto;
  width: 80%;
  line-height: 40px;
}

.Terms {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 40px auto;
}
.Refund {
  margin: 0 auto;
  width: 60%;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 5px 5px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 7px;
  height: 16px;
  width: 16px;
  margin: -8px auto 0;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  content: "\00d7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    background-color: #485c6b;/* old #485c6b new #243A49*/
}

.Container_Content {
    margin-top: 0px;
}
/* @import "../node_modules/normalize.css/normalize.css"; */
/* @import '../node_modules/@blueprintjs/core/dist/blueprint.css'; */
/*
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  background-image: none;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #e0e0e0;
  border-color: #cacaca;
}

.btn-default:hover {
  border-color: #d2d2d2;
} */

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  background-color: transparent;
}
a {
  color: #9AD8FF;
  border-bottom: 1px solid transparent;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
}

a:hover {
  border-bottom: 1px solid #9AD8FF;
}

/* a:active {
  text-de
} */

a, button {
  transition: 200ms;
}

.modal-header, .modal-body {
  color: black;
}
/*
.pt-input-group {
  margin-bottom: 10px;
}

.alert-info {
  background-image: linear-gradient(to bottom, #3a4c58 0, #394b59 100%);
  border-color: #485c6b;
}

.alert {
  color: white;
  text-shadow: 0 1px 0 rgb(72, 92, 107);
  box-shadow: inset 0 1px 0 rgb(72, 92, 107), 0 1px 2px rgb(72, 92, 107);
}

.alert-warning {
  border: none;
  background: transparent linear-gradient(to bottom, #f3a10c 0, #f3a10c 100%);
  text-shadow: none;
}

.panel-primary {
  border-color: #3a4c59;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #394b59;
  border-color: #394b58;
}

.panel-primary > .panel-heading {
  background-image: -webkit-linear-gradient(top, #394b59 0, #394b59 100%);
  background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#3a4c59),
    to(#394b59)
  );
  background-image: linear-gradient(to bottom, #394b59 0, #3a4c59 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#ff337ab7',
      endColorstr='#ff2e6da4',
      GradientType=0
    );
  background-repeat: repeat-x;
}

.panel.panel-primary a {
  color: #eeeeee;
  user-select: none;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #394b59;
}

.panel.panel-primary {
  background-color: #4f6575;
} */

.SelectColor__SketchPicker {
  color: black;
}

