@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Lora');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz');
@import url('https://fonts.googleapis.com/css?family=Lobster');
@import url('https://fonts.googleapis.com/css?family=Amatic+SC');
@import url('https://fonts.googleapis.com/css?family=Marmelad');
@import url('https://fonts.googleapis.com/css?family=Pattaya');
@import url('https://fonts.googleapis.com/css?family=Rubik+Mono+One');
@import url('https://fonts.googleapis.com/css?family=Acme');
@import url('https://fonts.googleapis.com/css?family=Baloo+Thambi');
@import url('https://fonts.googleapis.com/css?family=Bonbon');
@import url('https://fonts.googleapis.com/css?family=Bree+Serif');
@import url('https://fonts.googleapis.com/css?family=Cantarell:700i');
@import url('https://fonts.googleapis.com/css?family=Caveat');
@import url('https://fonts.googleapis.com/css?family=Charmonman');
@import url('https://fonts.googleapis.com/css?family=Clicker+Script');
@import url('https://fonts.googleapis.com/css?family=Courgette');
@import url('https://fonts.googleapis.com/css?family=Crafty+Girls');
@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url('https://fonts.googleapis.com/css?family=Dhurjati');
@import url('https://fonts.googleapis.com/css?family=Finger+Paint');
@import url('https://fonts.googleapis.com/css?family=Fredericka+the+Great');
@import url('https://fonts.googleapis.com/css?family=Gaegu');
@import url('https://fonts.googleapis.com/css?family=Great+Vibes');
@import url('https://fonts.googleapis.com/css?family=Gugi');
@import url('https://fonts.googleapis.com/css?family=Hi+Melody');
@import url('https://fonts.googleapis.com/css?family=Lancelot');
@import url('https://fonts.googleapis.com/css?family=League+Script');
@import url('https://fonts.googleapis.com/css?family=Life+Savers');
@import url('https://fonts.googleapis.com/css?family=Lily+Script+One');
@import url('https://fonts.googleapis.com/css?family=Love+Ya+Like+A+Sister');
@import url('https://fonts.googleapis.com/css?family=Milonga');
@import url('https://fonts.googleapis.com/css?family=Mogra');
@import url('https://fonts.googleapis.com/css?family=Monoton');
@import url('https://fonts.googleapis.com/css?family=Mr+Bedfort');
@import url('https://fonts.googleapis.com/css?family=Niconne');
@import url('https://fonts.googleapis.com/css?family=Parisienne');
@import url('https://fonts.googleapis.com/css?family=Ranga');
@import url('https://fonts.googleapis.com/css?family=Ruthie');
@import url('https://fonts.googleapis.com/css?family=Sacramento');
@import url('https://fonts.googleapis.com/css?family=Satisfy');
@import url('https://fonts.googleapis.com/css?family=Schoolbell');
@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light');
@import url('https://fonts.googleapis.com/css?family=Sofadi+One');
@import url('https://fonts.googleapis.com/css?family=Sofia');
@import url('https://fonts.googleapis.com/css?family=The+Girl+Next+Door');
@import url('https://fonts.googleapis.com/css?family=Ultra');
@import url('https://fonts.googleapis.com/css?family=VT323');
@import url('https://fonts.googleapis.com/css?family=Yeon+Sung');
@import url('https://fonts.googleapis.com/css?family=ZCOOL+KuaiLe');

.RenderTips {
  position: absolute;
  width: 100%;
  height: 100%;
}

.RenderTips_NY2019 {
  width: 400px;
  height: 400px;
  position: absolute;
  top: -136px;
  left: -140px;
}

.RenderTips_Tip {
  width: 150px;
  height: 150px;
  animation: 3s linear;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  font-size: 24px;
}

.RenderTips_Tip--animation_bottom-top {
  animation-name: Tip-bottom-top;
}

.RenderTips_Tip--animation_top-bottom {
  animation-name: Tip-top-bottom;
}

.RenderTips_Tip--animation_left-right {
  animation-name: Tip-left-right;
}

.RenderTips_Tip--animation_right-left {
  animation-name: Tip-right-left;
  left: auto;
  right: 0;
}

.RenderTips_Tip--animation_fade-in-out {
  animation-name: Tip-fade-in-out;
  top: 40%;
  left: 40%;
}

.RenderTips_Tip--animation_full-screen {
  animation-name: Tip-fade-in-out;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.RenderTips_Tip--animation_none {
  animation-name: None_animation;
  width: content;
  height: content;
  top: 30%;
  left: 40%;
}

.RenderTips_Tip--pause {
  animation-play-state: paused;
}

.RenderTips_Message {
  white-space: nowrap;
  width: max-content;
}

.RenderTips_Tip,
.RenderTips_TipImage,
.RenderTips_Tipper,
.RenderTips_Message,
.RenderTips_Amount {
  position: absolute;
}

.RenderTips_Background {
  padding: 0.108em 0.216em;
  border-radius: 0.216em;
}

.RenderTips_TipImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;
}


.RenderTips_Message.alignBottom,
.RenderTips_Amount.alignBottom,
.RenderTips_Tipper.alignBottom {
  bottom: 0;
  transform: translateY(50%);
}

.RenderTips_Message.alignTop,
.RenderTips_Amount.alignTop,
.RenderTips_Tipper.alignTop {
  top: 0;
  bottom: auto;
  transform: translateY(-50%);
}

.RenderTips_Message.alignMiddle,
.RenderTips_Amount.alignMiddle,
.RenderTips_Tipper.alignMiddle {
  top: auto;
  bottom: auto;
}

.RenderTips_Message.alignLeft,
.RenderTips_Amount.alignLeft,
.RenderTips_Tipper.alignLeft {
  left: 0;
  transform: translateX(-50%);
}

.RenderTips_Message.alignRight,
.RenderTips_Amount.alignRight,
.RenderTips_Tipper.alignRight {
  right: 0;
  left: auto;
  transform: translateX(50%);
}

.RenderTips_Message.alignCenter,
.RenderTips_Amount.alignCenter,
.RenderTips_Tipper.alignCenter {
  left: auto;
  right: auto;
}


.RenderTips_Message.alignTop.alignRight,
.RenderTips_Amount.alignTop.alignRight,
.RenderTips_Tipper.alignTop.alignRight {
  transform: translate(50%, -50%);
}

.RenderTips_Message.alignBottom.alignRight,
.RenderTips_Amount.alignBottom.alignRight,
.RenderTips_Tipper.alignBottom.alignRight {
  transform: translate(50%, 50%);
}

.RenderTips_Message.alignBottom.alignLeft,
.RenderTips_Amount.alignBottom.alignLeft,
.RenderTips_Tipper.alignBottom.alignLeft {
  transform: translate(-50%, 50%);
}

.RenderTips_Message.alignTop.alignLeft,
.RenderTips_Amount.alignTop.alignLeft,
.RenderTips_Tipper.alignTop.alignLeft {
  transform: translate(-50%, -50%);
}
/*
.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(1),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(1) {
  animation-delay: 0.1s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(2),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(2) {
  animation-delay: 0.15s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(3),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(3) {
  animation-delay: 0.2s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(4),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(4) {
  animation-delay: 0.12s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(5),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(5) {
  animation-delay: 0.08s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(6),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(6) {
  animation-delay: 0.25s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(7),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(7) {
  animation-delay: 0.13s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(8),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(8) {
  animation-delay: 0.3s;
}

.RenderTips_Amount.RenderTips_Amount--animated span:nth-child(9),
.RenderTips_Tipper.RenderTips_Tipper--animated span:nth-child(9) {
  animation-delay: 0.1s;
}
*/

@keyframes Tip-bottom-top {
  0% {
    opacity: 0;
    bottom: 0;
    left: 10%;
  }

  30% {
    opacity: 1;
  }

  40% {
    left: 30%;
    bottom: 50%;
  }

  60% {
    opacity: 1;
    bottom: 55%;
    left: 30%;
  }

  100% {
    opacity: 0;
    bottom: 100%;
    left: 0;
  }
}

@keyframes Tip-top-bottom {
  0% {
    opacity: 0;
    top: 0;
    left: 10%;
  }

  30% {
    opacity: 1;
  }

  40% {
    left: 30%;
    top: 50%;
  }

  60% {
    opacity: 1;
    top: 55%;
    left: 30%;
  }

  100% {
    opacity: 0;
    top: 100%;
    left: 0;
  }
}

@keyframes Tip-left-right {
  0% {
    opacity: 0;
    left: 0;
    top: 50%;
  }

  30% {
    opacity: 1;
  }

  40% {
    top: 30%;
    left: 50%;
  }

  60% {
    opacity: 1;
    left: 55%;
    top: 30%;
  }

  100% {
    opacity: 0;
    left: 100%;
    top: 50%;
  }
}

@keyframes Tip-right-left {
  0% {
    opacity: 0;
    right: 0;
    top: 50%;
  }

  30% {
    opacity: 1;
  }

  40% {
    top: 30%;
    right: 50%;
  }

  60% {
    opacity: 1;
    right: 55%;
    top: 30%;
  }

  100% {
    opacity: 0;
    right: 100%;
    top: 50%;
  }
}

@keyframes Tip-fade-in-out {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes None_animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}