/* @import "../node_modules/normalize.css/normalize.css"; */
/* @import '../node_modules/@blueprintjs/core/dist/blueprint.css'; */
/*
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  background-image: none;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #e0e0e0;
  border-color: #cacaca;
}

.btn-default:hover {
  border-color: #d2d2d2;
} */

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  background-color: transparent;
}
a {
  color: #9AD8FF;
  border-bottom: 1px solid transparent;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
}

a:hover {
  border-bottom: 1px solid #9AD8FF;
}

/* a:active {
  text-de
} */

a, button {
  transition: 200ms;
}

.modal-header, .modal-body {
  color: black;
}
/*
.pt-input-group {
  margin-bottom: 10px;
}

.alert-info {
  background-image: linear-gradient(to bottom, #3a4c58 0, #394b59 100%);
  border-color: #485c6b;
}

.alert {
  color: white;
  text-shadow: 0 1px 0 rgb(72, 92, 107);
  box-shadow: inset 0 1px 0 rgb(72, 92, 107), 0 1px 2px rgb(72, 92, 107);
}

.alert-warning {
  border: none;
  background: transparent linear-gradient(to bottom, #f3a10c 0, #f3a10c 100%);
  text-shadow: none;
}

.panel-primary {
  border-color: #3a4c59;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #394b59;
  border-color: #394b58;
}

.panel-primary > .panel-heading {
  background-image: -webkit-linear-gradient(top, #394b59 0, #394b59 100%);
  background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#3a4c59),
    to(#394b59)
  );
  background-image: linear-gradient(to bottom, #394b59 0, #3a4c59 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#ff337ab7',
      endColorstr='#ff2e6da4',
      GradientType=0
    );
  background-repeat: repeat-x;
}

.panel.panel-primary a {
  color: #eeeeee;
  user-select: none;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #394b59;
}

.panel.panel-primary {
  background-color: #4f6575;
} */

.SelectColor__SketchPicker {
  color: black;
}
