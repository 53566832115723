.RenderTips_Tip--animation_bounceInDown,
.RenderTips_Tip--animation_bounceInUp,
.RenderTips_Tip--animation_bounceIn,
.RenderTips_Tip--animation_bounceInRight,
.RenderTips_Tip--animation_bounceInLeft,
.RenderTips_Tip--animation_zoomIn,
.RenderTips_Tip--animation_jackInTheBox,
.RenderTips_Tip--animation_zoomInDown,
.RenderTips_Tip--animation_zoomInUp,
.RenderTips_Tip--animation_fadeInUp {
  left: 30%;
  top: 30%;
}

.RenderTips_Tip--animation_fadeInUp {
    animation-name: fadeInUp;
  }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0%, 100%, 0);
  }
  45%,
  55% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0%, -200%, 0);
  }
}

.RenderTips_Tip--animation_bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInLeft {
  0%,
  60%,
  75%,
  80% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  55% {
    transform: translate3d(-10px, 0, 0);
  }
  70%,
  80% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_Tip--animation_bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInRight {
  0%,
  60%,
  75%,
  80% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  55% {
    transform: translate3d(10px, 0, 0);
  }
  70%,
  80%  {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_Tip--animation_bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.RenderTips_Tip--animation_bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInDown {
  0%,
  60%,
  75%,
  80% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  55% {
    transform: translate3d(0, -10px, 0);
  }
  70%,
  80% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_Tip--animation_bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceInUp {
  0%,
  60%,
  75%,
  80% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  40% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  55% {
    transform: translate3d(0, 10px, 0);
  }
  70%,
  80% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_Tip--animation_zoomIn{
  animation-name: zoomIn;
  animation-timing-function: ease-in;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
    animation-timing-function: cubic-bezier(0.45, 0.31, 0, 1.1);
  }
  30%{
    opacity: 1;
  }
}

.RenderTips_Tip--animation_jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }
  50% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.RenderTips_Tip--animation_zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  30% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
  50% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
}

.RenderTips_Tip--animation_zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  30% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
  50% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    animation-timing-function: cubic-bezier(1, 0.19, 0.87, 0.05);
  }
}
