.range-slider {
  
}

.range-slider > div {
  display: flex;
}

.range-slider > div > span {
  margin-right: 20px;
}