.RenderTips_TipImage--animated_classic {
  animation: 1s TipImage infinite linear;
}

.RenderTips_Message--animated_classic span,
.RenderTips_Amount--animated_classic,
.RenderTips_Tipper--animated_classic {
  display: inline-block;
  letter-spacing: 2px;
}

.RenderTips_Amount--animated_classic {
  transform: scale(2);
  letter-spacing: 4px;
}

.RenderTips_Message--animated_classic,
.RenderTips_Amount--animated_classic,
.RenderTips_Tipper--animated_classic {
  animation: 1s TipperNameLetters infinite linear;
}

@keyframes TipperNameLetters {
  0% {
    transform: scale(1);
  }

  25% {
    transform: rotate(20deg);
  }

  50% {
    transform: scale(1.4) rotate(0);
  }

  75% {
    transform: rotate(-20deg);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes TipImage {
  0% {
    transform: translateX(0) scale(1);
  }

  25% {
    transform: translateX(-10%) scale(1.2) rotate(10deg);
  }

  50% {
    transform: translateX(0) scale(0.8);
  }

  75% {
    transform: translateX(10%) scale(1) rotate(-10deg);
  }

  100% {
    transform: translateX(0);
  }
}

.RenderTips_TipImage--animated_bounce,
.RenderTips_Message--animated_bounce,
.RenderTips_Amount--animated_bounce,
.RenderTips_Tipper--animated_bounce {
  animation: 2s bounce infinite linear;
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.RenderTips_TipImage--animated_flash,
.RenderTips_Message--animated_flash,
.RenderTips_Amount--animated_flash,
.RenderTips_Tipper--animated_flash {
  animation: 4s flash infinite linear;
}

@keyframes flash {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.RenderTips_TipImage--animated_pulse,
.RenderTips_Message--animated_pulse,
.RenderTips_Amount--animated_pulse,
.RenderTips_Tipper--animated_pulse {
  animation: 2s pulse infinite ease-in-out;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.25, 1.25, 1.25);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.RenderTips_TipImage--animated_rubberBand,
.RenderTips_Message--animated_rubberBand,
.RenderTips_Amount--animated_rubberBand,
.RenderTips_Tipper--animated_rubberBand {
  animation: 2s rubberBand infinite ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.RenderTips_TipImage--animated_shake,
.RenderTips_Message--animated_shake,
.RenderTips_Amount--animated_shake,
.RenderTips_Tipper--animated_shake {
  animation: 2s shake infinite ease-in-out;
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.RenderTips_TipImage--animated_swing,
.RenderTips_Message--animated_swing,
.RenderTips_Amount--animated_swing,
.RenderTips_Tipper--animated_swing {
  animation: 2s swing infinite ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.RenderTips_TipImage--animated_tada,
.RenderTips_Message--animated_tada,
.RenderTips_Amount--animated_tada,
.RenderTips_Tipper--animated_tada {
  animation: 2s tada infinite ease-in-out;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.RenderTips_TipImage--animated_wobble,
.RenderTips_Message--animated_wobble,
.RenderTips_Amount--animated_wobble,
.RenderTips_Tipper--animated_wobble {
  animation: 2s wobble infinite ease-in-out;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.RenderTips_TipImage--animated_jello,
.RenderTips_Message--animated_jello,
.RenderTips_Amount--animated_jello,
.RenderTips_Tipper--animated_jello {
  animation: 2s jello infinite ease-in-out;
}

@keyframes jello {
  from,
  11.1%,
  to {
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.RenderTips_TipImage--animated_heartBeat,
.RenderTips_Message--animated_heartBeat,
.RenderTips_Amount--animated_heartBeat,
.RenderTips_Tipper--animated_heartBeat {
  animation: 1.3s heartBeat infinite ease-in-out;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
}
