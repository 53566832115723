.Auth {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.Auth__Item {
    margin-top: 20px;
}

.Auth__Options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 26px;
}
