.Home {
  margin: 20px auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Home tbody td {
  text-align: center;
}

.Home tbody td:first-child {
  text-align: left;
}

.Home .RenderTips_Tip {
  animation-iteration-count: infinite;
  top: 0;
  left: 0;
}